import Vue from 'vue'
import VueSimpleAlert from 'vue-simple-alert'

Vue.use(VueSimpleAlert)

export const NotificationMixin = {
  methods: {
    displayError500() {
      this.$alert(
        this.$t('errors.500-alert-message').toString(),
        this.$t('errors.500-alert-title').toString(),
        'warning'
      )
    },
    displayError(message) {
      if (message && message.length > 0) {
        this.makeToast(message, this.$t('general.error'), 'danger')
      }
    },
    displaySuccess(message) {
      if (message && message.length > 0) {
        this.makeToast(message, this.$t('general.success'), 'success')
      }
    },
    makeToast(message, title, variant = null) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      })
    }
  }
}
